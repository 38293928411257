import locales from "."

export type MessageSchema = typeof locales.en
export type Locales = keyof typeof locales

export function getI18NConf() {
    const userLang = navigator.language || navigator.userLanguage;
	console.log(userLang);
	const locale = userLang == 'en' || userLang == 'es' ? userLang : 
						localStorage.getItem("language") != undefined ? localStorage.getItem("language") == "Inglés" ? "en" : "es" 
					: 'es'
	localStorage.setItem("language", locale == "en" ? "Inglés" : "Español")
	return {
		legacy: false,
		locale:  locale,
		messages: locales
	}
}
