import { defineStore, acceptHMRUpdate } from "pinia"
import type { Role, Roles } from "@/types/auth.d"
import _castArray from "lodash/castArray"
import { axiosToken } from "@/utils/http"
// HERE YOU CAN IMPLEMENT YOUR LOGIN

export const useAuthStore = defineStore("auth", {
	state: () => ({
		logged: localStorage.getItem("user") != null,
		role: "admin" as Role | null,
		user: {},
		full_user:{},
		// ORGANIZATIOn
		users_organization:[],
	}),
	actions: {
		setLogged(payload?: any) {
			this.logged = true
			this.role = "admin"
			this.user = payload
		},
		setLogout() {
			this.logged = false
			this.role = null
			this.user = {}
			localStorage.removeItem("user")
		},


		// ORGANIZATION
		async fetchUsers(){
			await axiosToken("GET","users/get_users/").then((response)=>{
				this.users_organization = response.data
			})
		},
		async getUser() {
            await axiosToken("get", "users/user/").then((response: any) => {
                const user_data: {
                    email: string;
                    password: string;
                    name: string;
                    rol: number;
                    avatar: string | null;
                    id: number;
                } = {
                    email: response.data.email,
                    password: "",
                    name: response.data.name,
                    rol: response.data.rol,
                    avatar: response.data.avatar_url,
                    id: response.data.id,
                };
				this.full_user = response.data
                this.user = user_data;
				if (response.data.rol === 1) {
					this.fetchUsers();
				}
            }).catch((response:any)=>{
				this.logged = false;
				localStorage.removeItem("user")
			});
        },
		
	},
	getters: {
		isLogged(state) {
			return state.logged
		},
		userRole(state) {
			return state.role
		},
		isRoleGranted(state) {
			return (roles?: Roles) => {
				if (!roles) {
					return true
				}
				if (!state.role) {
					return false
				}

				const arrRoles: Role[] = _castArray(roles)

				if (arrRoles.includes("all")) {
					return true
				}

				return arrRoles.includes(state.role)
			}
		},
		getUserById(state){
			return (id: number) => {
				const user=  state.users_organization.find(user => user.id === id);
				if(user){
					return user
				}
				return {
					name: ""
				}
			}
		}	
		
	},
	persist: {
		paths: ["logged", "role"]
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
