export default {
        italian: "Italian",
        english: "English",
        japanese: "Japanese",
        french: "French",
        german: "German",
        spanish: "Spanish",
        general: {
            back: "Back",
            go: "Go",
            next: "Next",
            team: "Team",
            round: "Round",
            ranking: "Ranking",
            results: "Results",
            total: "Total",
            remaining: "Remaining",
            answer: "Answer",
            units: "Available",
            shop: "Shop",
            ingredients: "Ingredients",
            rices: "Rice",
            plates: "Plates",
            choosing: "Choosing",
            save: "Save",
            cancel: "Cancel",
            name: "Name",
            buy: "Buy",
            photo: "Photo",
            redo: "Redo",
            password: "Password",
            exit: "Exit",
            canceled: "Cancelled",
            event: "Event",
            day: "Day",
            ingredient: "Ingredient",
            points: "Points",
            position: "Position",
            start: "Start",
            from: "Up to",
            error: "Error",
            chat: "Chat",
            game_master: "STAFF",
            options: "Options",
            team_name: "Team Name",
            option_organizer: "STAFF Options",
            btn_send_answer: "Send answer",
            btn_continue: "Continue",
            correct_answer: "Correct answer",
            incorrect_answer: "Wrong answer",
            challenge_complete: "Challenge completed",
            try_again: "Error, try again",
            save_changed: "Saved changes",
            instructor: "Instructor",
            ranking_block: "The admin has hidden the ranking",
            close: "Close",
            agency: "TODO: Agencia",
            delete: "TODO: Eliminar",
            updated: "TODO: actualizado",
            created: "TODO: creado",
            update: "TODO: actualizar",
            create: "TODO: crear",
            filters: "TODO: Filtros",
            add: "TODO: Añadir",
            edit: "TODO: Editar",
            accept: "TODO: Aceptar",
            unsaved_changes: "TODO: Hay cambios sin guardar",
            en: "TODO: Inglés",
            es: "TODO: Español",
            yes: "TODO: Si",
            no: "TODO: No",
            ipad_title: "TODO: Ipad Hunt",
            tapas_title: "TODO: Tapas, Paella, Sangria",
            utq_title: "TODO: Ultimate Team Quest",
            action_title: "TODO: Action Challenge",
            hours: "TODO: Horas",
            minutes: "TODO: Minutos",
            new: "TODO: Nuevo",
            mark_all_read: "TODO: Marcar todo como leido",
            name_app: "TODO: TEAMBUILDING APP",
            hello: "TODO: Hola",
            reload_app: "TODO: Recargar APP",
            change_monitor: "TODO: Cambiar monitor",
            must: "TODO: Obligatorio",
            first: "TODO: Primero",
            incorrect_fields: "TODO: Hay campos incorrectos en el formulario",
            answer_correct: "TODO: Respuesta correcta",
            answer_incorrect: "TODO: Respuesta incorrecta",
            pair_a: "TODO: Pareja A ",
            pair_b: "TODO: Pareja B ",
            order_colon: "TODO: Orden: ",
            quiz_multi: "TODO: Cuestionario multiple",
            answer_auto: "TODO: Respuesta autocorregida",
            order: "TODO: Order",
            click_add: "TODO: Click para añadir",
            click_upload: "TODO: Click para subir",
            quiz: "TODO: Cuestionario",
            pairs: "TODO: Parejas",
            min_answers: "TODO: Tiene que haber un minimo de {min} respuestas",
            max_answers: "TODO: El limite de respuestas son {max}",
            file_large: "TODO: Archivo demasiado pesado el maximo son 50MB",
            new_item: "TODO: Nuevo item",
            add_item: "TODO: Añade un item",
            missing_items: "TODO: No hay ningun item añadido",
            select_item: "TODO: Selecciona un item",
            total_price: "TODO: Total {total_price}€",
            app_activity_plus: "TODO: + App Actividad",
            app_activity: "TODO: App Actividad",
            url_admin: "TODO: URL Admin",
            url_gm: "TODO: URL GM"
        },
        player_views: {
            event: {

            },
            home: {
                user_registered: "User registered",
                active_events: "View active events",
                insert_code: "Enter event code // team",
                qr_scan: "Scan QR",
                code_event: "Event code",
                code_team: "Team code",
                event_not_exist: "This event does not exist",
                team_not_exist: "This team does not exist"
            },
            player_view: {

            }
        },
        public_views: {
            tapas: {
                presentation_tapas: {
                    end: "End of presentation"
                },
                ranking_tapas: {
                    round_ends: "Round ends",
                    team_turn: "Team turn",
                    choice_type: "Choice type",
                    choice_round: "Choice round",
                    total_round: "Total round",
                    time_preparation: "Preparation time"
                },
                results_tapas: {

                }
            },
            utq: {
                ranking_utq: {
                    round_ends: "Round ends"
                },
                results_utq: {

                }
            }
        },
        tapas: {
            tapas_player: {
                change_name: "Change name",
                time_choose: "Time to choose",
                presentation_in: "Presentation in",
                final_presentation: "Final presentation",
                dish_name_PC: "Paella name",
                dish_name_Tapas: "Tapa name",
                dish_name_MS: "Sangria name",
                team_photo: "Team photo",
                dish_photo_PC: "Paella photo",
                dish_photo_Tapas: "Tapa photo",
                dish_photo_MS: "Sangria photo",
                available_money: "Available money",
                choose_ingredient: "Choose ingredient",
                team_choosing: "Team choosing",
                choose_rice: "Choose rice",
                choose_plates: "Choose plate",
                choose_decoration: "Choose decoration/extra",
                change_team_name: "Change team name",
                buy_confirm: "Confirm purchase",
                meal_photo: "Meal photo",
                exit_event: "Exit event",
                answer_correct: "The answer is correct",
                answer_incorrect: "The answer is incorrect",
                name_sended: "Name sent",
                item_purhased: "Item purchased, you can now go pick it up at the store",
                no_unit: "No units available",
                password_incorrect: "Incorrect password",
                name_changed: "Name changed",
                item_purchased: "Purchased utensil"
            }
        },
        modals: {
            upload_photo: {
                change_camera: "Change Camera",
                upload_photo: "Upload Photo",
                photo_success: "TODO: Foto enviada"
            }
        },
        stores: {
            tapas: {
                main_ingredient: "Compulsory ingredient",
                global_winner: "Global",
                flavour_winner: "Flavour",
                presentation_winner: "Present.",
                defense_winner: "Defense",
                money_winner: "Money",
                photo_tapa: "P. TAPA",
                photo_team: "P. TEAM",
                tapa: "TAPA",
                position: "POSITION",
                points: "POINTS"
            }
        },
        chat: {
            message: "Message",
            message_placeholder: "Type",
            message_btn: "Send message",
            send: "Sent",
            read: "Read"
        },
        ipadHunt: {
            ipadHunt_player: {
                must_challenge: "Compulsory challenge",
                modal_password: "Ask a member of the STAFF for the password.",
                modal_error_download_title: "Content not saved",
                modal_error_download_text: "Download content again",
                modal_download_content_title: "Downloading content",
                modal_download_content_text: "Downloading content",
                modal_error_location_title: "Location not available",
                modal_error_location_text: "Give the app permission to use your location or contact a member of the staff.",
                reload_app: "Refresh app",
                content_correct: "Content downloaded correctly",
                content_incorrect: "There are errors in the content. Please download again.",
                delete_content: "Delete content and download again.",
                answer_send: "Your answer has been submitted. You will receive the corresponding points in a few minutes.",
                match: "Match",
                take_photo: "Take photo",
                take_video: "Take video",
                error_wrong_password: "Wrong password",
                error_no_answer: "Answer not detected",
                error_order: "You have to order the answers",
                test_camera: "Test camera",
                modal_test_title: "TEST",
                modal_test_localization_correct: "Correct Location",
                modal_test_localization_incorrect: "Location not available",
                modal_test_ok: "Test completed correctly"
            }
        },
        actionChallenge: {
            actionChallenge_player: {
                audio_error: "Your browser does not support the audio element.",
                need_material: "En esta prueba necesitas material",
                continue: "CONTINUAR",
                send_response: "ENVIAR RESPUESTA",
                correct_response: "RESPUESTA CORRECTA",
                correct_responses: "RESPUESTAS CORRECTAS",
                incorrect_response: "RESPUESTA INCORRECTA",
                money_won: "Dinero ganado",
                response: "Respuesta",
                btn_match: "Match",
                successes: "Aciertos",
                similarity: "La palabra coincide un {similarity_percentage}% a las palabras correctas",
                category_no_elements: "No hay elementos en esta categoria",
                not_started: "EL EVENTO TODAVIA NO HA EMPEZADO",
                challenge_warning: "Tras comprar un reto, ve a buscar el material a la tienda para completarlo.",
                pick_material: "VE A BUSCAR EL MATERIAL A LA TIENDA PARA EMPEZAR ESTE RETO",
                challenge_send: "LA RESPUESTA HA SIDO ENVIADA Y SERA EVALUADA POR EL GAME MASTER",
                return_material: "DEVUELVE EL MATERIAL A LA TIENDA PARA SELECCIONAR EL SIGUIENTE RETO",
                points_won: "Puntos ganados",
                wrong_answer: "RESPUESTA INCORRECTA",
                game_finished: "LA PARTIDA HA FINALIZADO",
                return_material_finish: "DEVUELVE EL MATERIAL DEL RETO A LA TIENDA",
                no_material: "En estos momentos no hay material suficiente para hacer esta prueba",
                no_money: "No tienes dinero suficiente para comprar esta prueba",
                shop: "TODO: Tienda",
                obejcts_buy: "TODO: Objectos comprados",
                quantity: "TODO: Cantidad",
                modal_buy_text: "TODO: Comprar {object} por {dinero}$",
                error_item_buy_no_money: "TODO: No tienes suficiente dinero",
                error_item_buy_no_stock: "TODO: No queda stock",
                error_item_buy_can_not_more: "TODO: No puedes compar mas objectos de estos",
                ok_item_buy: "TODO: Objecto comprado, ya puedes recogerlo",
                construction_name: "TODO: Nombre de la construcion",
                construction_photo: "TODO: Foto de la construcion"
            }
        },
        camera: {
            title_error_modal: "Camera error",
            text_error_modal: "Ask a member of the STAFF for support. Error: ",
            btn_reload_app: "Refresh App",
            btn_load_foto: "Add picture from gallery",
            modal_upload_title: "Upload Photo",
            modal_upload_text: "You can upload a picture from the gallery",
            error_no_upload: "Upload a picture first",
            error_upload_photo: "Photo not uploaded, try again"
        },
        menu: {
            sales: "TODO: Ventas",
            budgets: "TODO: Presupuestos",
            clients: "TODO: Clientes",
            bookings: "TODO: Reservas",
            booking_list: "TODO: Lista de reservas",
            calendar: "TODO: Calendario",
            activities: "TODO: Actividades",
            demos: "TODO: Demos",
            content: "TODO: Contenido",
            settings: "TODO: Configuraciones",
            settings_erp: "TODO: Configuraciones ERP",
            organization: "TODO: Organización",
            activities_erp: "TODO: Actividades",
            extras: "TODO: Extras",
            final_page_budget: "TODO: Pagina final presupuesto",
            activities_questions: "TODO: Preguntas actividades",
            notifications: "TODO: Notificaciones",
            logout: "TODO: Cerrar sesión"
        },
        budgets: {
            budgets: "TODO: Presupuestos",
            status: "TODO: Estado",
            search: "TODO: Buscar",
            search_name: "TODO: Buscar por nombre",
            name: "TODO: Nombre",
            client: "TODO: Cliente",
            people: "TODO: Personas",
            date: "TODO: Fecha",
            open: "TODO: Abierto",
            won: "TODO: Ganado",
            lost: "TODO: Perdido",
            all: "TODO: Todos",
            new_budget: "TODO: Nuevo Presupuesto",
            must_name: "TODO: Debe tener un nombre",
            budget_validity: "TODO: Validez presupuesto",
            hour: "TODO: Hora",
            show_total: "TODO: Mostrar total",
            language: "TODO: Idioma",
            spanish: "TODO: Español",
            english: "TODO: Ingles",
            conditions: "TODO: Condiciones",
            extras: "TODO: Extras",
            budget: "TODO: Presupuesto",
            close_budget: "TODO: Cerrar Presupuesto",
            download: "TODO: Descargar",
            select_option: "TODO: Selecciona una opción",
            select_items: "TODO: Selecciona los items que se transferiran a la reserva",
            comments_close_budget: "TODO: Comentarios sobre el cierre del presupuesto",
            convert: "TODO: Convertir",
            close: "TODO: Cerrar",
            back: "TODO: Volver",
            cancel: "TODO: Cancelar",
            line: "TODO: linea",
            auto_price: "TODO: Precio Automatico",
            total: "TODO: Total",
            description: "TODO: Descripción",
            price_tag: "TODO: Tag precio",
            pax: "TODO: Pax",
            price: "TODO: Price",
            user: "TODO: Usuario"
        },
        bookings: {
            bookings: "TODO: Reservas",
            name: "TODO: Nombre",
            client: "TODO: Cliente",
            people: "TODO: Personas",
            date: "TODO: Fecha",
            search_name: "TODO: Buscar por nombre",
            search: "TODO: Buscar",
            new_booking: "TODO: Nueva Reserva",
            general: "TODO: General",
            event: "TODO: Evento",
            questions: "TODO: Preguntas",
            economics: "TODO: Economics",
            ratings: "TODO: Valoraciones",
            language: "TODO: Idioma",
            hour: "TODO: Hora",
            client_logo: "TODO: Logo del cliente",
            billing_data: "TODO: Datos de facturación",
            activities_app: "TODO: Actividades App",
            type_event: "TODO: Tipo de evento",
            place: "TODO: Sitio",
            url_location: "TODO: URL Ubicación",
            place_information: "TODO: Información del sitio",
            location: "TODO: Ubicación",
            must_name: "TODO: Debe tener un nombre",
            general_information: "TODO: Información general",
            extra_information: "TODO: Información extra",
            extra_information_internal: "TODO: Información extra interna",
            timeline: "TODO: Horario",
            description: "TODO: Descripción",
            add_activity: "TODO: Añadir actividad",
            number_teams: "TODO: Numero de equipos",
            staff_people: "TODO: Personas de Staff",
            teams: "TODO: Equipos",
            staff: "TODO: Staff",
            add_team: "TODO: Añadir equipo",
            add_staff: "TODO: Añadir persona de staff",
            contact_person: "TODO: Persona de contacto",
            add_person_contact: "TODO: Añadir persona de contacto",
            add_item: "TODO: Anade un item",
            add_questions: "TODO: Añadir Preguntas",
            modify_questions: "TODO: Modificar preguntas",
            add: "TODO: Añadir",
            all_questions: "TODO: Todas las preguntas",
            activity_questions: "TODO: Preguntas de la actividad",
            costs: "TODO: Costes",
            price: "TODO: Precio",
            activity: "TODO: Actividad",
            reserve_item: "TODO: Item Reserva",
            gm_pay: "TODO: GM Pay",
            add_gm: "TODO: Añadir GM",
            game_master: "TODO: Game master",
            salary: "TODO: Salario",
            total: "TODO: Total",
            gm_valoration: "TODO: Valoración del game master",
            client_valoration: "TODO: Valoración del cliente",
            es: "TODO: Español",
            en: "TODO: Inglés",
            cat: "TODO: Català",
            email: "TODO: Email",
            phone: "TODO: Telefono",
            extra: "TODO: Extra",
            online: "TODO: Online",
            in_person: "TODO: Presencial",
            booking_activities: "TODO: Actividades App",
            name_place: "TODO: Nombre del sitio",
            direction_location: "TODO: Dirección url sitio",
            add_cost: "TODO: Añadir coste",
            add_field: "TODO: Añadir campo",
            review_gm_enjoy: "TODO: ¿Los participantes se lo han pasado bien?",
            review_gm_size: "TODO: ¿Cómo valoras el dimensionado del equipo de monitores?",
            review_gm_previous_info: "TODO: ¿Cómo valoras la información previa a la actividad?",
            review_gm_started: "TODO: ¿Cuándo ha empezado la actividad?",
            review_gm_material: "TODO: ¿Se ha dañado o perdido algún material?",
            review_gm_comments: "TODO: ¿Comentarios o incidencias?",
            review_expectations: "TODO: ¿Hasta que punto nuestros servicios han cumplido tus expectativas?",
            review_scale: "TODO: Del 1 al 10, ¿qué tan probable es que recomiendes nuestros servicios a alguien de tu entorno? ",
            review_gamemasters: "TODO: ¿Cómo calificarías la atención de los dinamizadores en la actividad?",
            review_content: "TODO: ¿Cómo calificarías el contenido de la actividad?",
            review_service_prices: "TODO: ¿Cómo calificarías el precio de los servicios?",
            review_previous_organization: "TODO: ¿Cómo calificarías la organización previa a la actividad?",
            review_hire_again: "TODO: ¿Cuáles son las probabilidades de que vuelvas a contratar nuestro servicio?",
            review_comments: "TODO: ¿Tienes algún comentario o sugerencia para nosotros?",
            expected_not: "TODO: No han cumplido mis expectativas",
            expected_more: "TODO: Me esperaba más",
            expected_fulfilled: "TODO: Han cumplido con lo que esperaba",
            expected_exceeded: "TODO: Han superado mis expectativas",
            very_unsatisfactory: "TODO: Muy insatisfactoria",
            unsatisfactory: "TODO: Insatisfactoria",
            neutral: "TODO: Neutra",
            satisfactory: "TODO: Satisfactoria",
            very_satisfactory: "TODO: Muy satisfactoria",
            no_complaints: "TODO: No, han habido muchas quejas",
            standing_group: "TODO: No mucho, grupo parado",
            generally_yes: "TODO: Generalmente sí",
            highly_motivated: "TODO: Sí, grupo muy motivado",
            staff_shortage_critic: "TODO: Faltaba personal críticamente",
            staff_shortage: "TODO: Faltaba personal para ir cómodos",
            correct: "TODO: Correcto",
            staff_excess: "TODO: Sobraba personal",
            missing_information: "TODO: Faltaba información importante",
            missing_details: "TODO: Faltaban detalles",
            enough: "TODO: Suficiente",
            surplus: "TODO: De sobras",
            early_expected: "TODO: Más temprano de lo esperado",
            on_point: "TODO: En punto",
            ten_late: "TODO: 0-10 min tarde",
            thirty_late: "TODO: 10-30 min tarde",
            more_late: "TODO: +30 min tarde",
            user: "TODO: Usuario"
        },
        activities: {
            activities: "TODO: Actividades",
            activity: "TODO: Actividad",
            game: "TODO: Juego",
            day: "TODO: Dia",
            enter: "TODO: Entrar",
            create_activity: "TODO: Crear Actividad",
            sure: "TODO: Estas seguro?",
            delete: "TODO: Eliminar",
            date_hour: "TODO: Fecha y hora",
            name_activity: "TODO: Nombre de la actividad",
            type_activity: "TODO: Tipo de actividad",
            language: "TODO: Idioma",
            comments: "TODO: Comentarios",
            logo: "TODO: Logo",
            demo: "TODO: Demo",
            alert_name_activity: "TODO: Pon un nombre al la actividad.",
            select_language: "TODO: Selecciona un idioma",
            select_challenge: "TODO: Selecciona alguna prueba",
            select_date: "TODO: Selecciona una fecha",
            select_activity: "TODO: Selecciona un tipo de actividad",
            select_name: "TODO: Pon un nombre a la actividad",
            select_primary_color: "TODO: Selecciona un color primario.",
            select_secondary_color: "TODO: Selecciona un color secundario.",
            save_error: "TODO: No se ha podido {message_request_negative} el evento",
            save_ok: "TODO: Evento {message_request} correctamente",
            delete_error: "TODO: No se ha podido eliminar el evento",
            delete_ok: "TODO: Se ha eliminado el evento",
            tl_activity: "TODO: Actividad Teaming Labs",
            click_add: "TODO: Click para Añadir",
            dashboard: "TODO: Dashboard"
        },
        demos: {
            demos: "TODO: Demos",
            create_demo: "TODO: Crear Demo"
        },
        content: {
            content: "TODO: Contenido",
            list_content: "TODO: Lista de contenido",
            no_template: "TODO: Sin plantilla",
            all: "TODO: Todos",
            ipad: {
                ipad_challenges: "TODO: Retos Ipad",
                must: "TODO: Obligatorio",
                optional: "TODO: Opcional",
                first: "TODO: Primero",
                content_list: "TODO: Lista de Contenido",
                name: "TODO: Nombre",
                setting: "TODO: Configuración",
                type: "TODO: Tipo",
                quiz: "TODO: Cuestionario",
                open_text: "TODO: Texto libre",
                photo: "TODO: Foto",
                video: "TODO: Video",
                alert: "TODO: Alerta",
                team_photo: "TODO: Foto de equipo",
                order: "TODO: Order",
                match: "TODO: Match",
                type_challenge: "TODO: Tipo de prueba",
                id_name: "TODO: Nombre identificativo",
                password: "TODO: Constraseña",
                time: "TODO: Tiempo",
                max_points: "TODO: Puntos maximos",
                template: "TODO: Plantilla",
                description: "TODO: Descripción",
                file_1: "TODO: File 1",
                file_2: "TODO: File 2",
                file_3: "TODO: File 3",
                placeholder_type: "TODO: Selecciona un tipo",
                alert_type: "TODO: Selecciona un tipo de prueba",
                placeholder_name_id: "TODO: Nombre identificador de la prueba...",
                alert_name_id: "TODO: Pon un nombre identificativo a la prueba.",
                placeholder_name: "TODO: Nombre de la prueba...",
                placeholder_password: "TODO: Contraseña...",
                placeholder_description: "TODO: Descripción de la prueba...",
                expected_answer: "TODO: Respuesta Esperada",
                placeholder_expected_answer: "TODO: Respuesta esperada",
                challenge: "TODO: Reto",
                placeholder_team: "TODO: Equipo.."
            },
            tapas: {
                tapas_challenges: "TODO: Retos Tapas",
                ingredients: "TODO: Ingredientes",
                item_shop: "TODO: Tienda de utensilios",
                name: "TODO: Nombre",
                type: "TODO: Tipo",
                quiz: "TODO: Cuestionario",
                challenge: "TODO: Reto",
                type_challenge: "TODO: Tipo de prueba",
                id_name: "TODO: Nombre Identificativo",
                money: "TODO: Dinero",
                template: "TODO: Plantilla",
                image: "TODO: Imagen",
                description: "TODO: Descripción",
                placeholder_type: "TODO: Selecciona un tipo",
                alert_type: "TODO: Selecciona un tipo de prueba",
                placeholder_name_id: "TODO: Nombre identificador de la prueba...",
                alert_name_id: "TODO: Pon un nombre identificativo a la prueba.",
                placeholder_name: "TODO: Nombre de la prueba...",
                placeholder_description: "TODO: Descripción de la prueba...",
                setting: "TODO: Configuración",
                main_ingredient: "TODO: Ingrediente principal",
                secondary_ingredient: "TODO: Ingrediente secundario",
                decoration: "TODO: Decoración",
                base: "TODO: base",
                quantity: "TODO: Cantidad",
                quantity_placeholder: "TODO: Cantidad, Ej: 1 paquete 200gr..."
            },
            utq: {
                challenges_utq: "TODO: Retos UTQ",
                name: "TODO: Nombre",
                placeholder_name: "TODO: Nombre de la prueba...",
                id_name: "TODO: Nombre Identificativo",
                material: "TODO: Material",
                placeholder_material: "TODO: Material...",
                description: "TODO: Descripción",
                placeholder_description: "TODO: Descripción de la prueba...",
                description_score: "TODO: Descripción Puntuación",
                placeholder_description_score: "TODO: Decripcion de la puntuación de la prueba...",
                image: "TODO: Imagen",
                quiz: "TODO: Cuestionario",
                challenge: "TODO: Reto",
                placeholder_name_id: "TODO: Nombre identificador de la prueba...",
                alert_name_id: "TODO: Pon un nombre identificativo a la prueba."
            },
            action: {
                quiz: "TODO: Cuestionario",
                category_quiz: "TODO: Categoria Cuestionario",
                challenge: "TODO: Reto",
                challenges: "TODO: Retos",
                name: "TODO: Nombre",
                category: "TODO: Categoria",
                type: "TODO: Tipo",
                setting: "TODO: Configuración",
                init_challenge: "TODO: Reto inicial",
                init_challenges: "TODO: Retos iniciales",
                order: "TODO: Ordenación",
                match: "TODO: Emparejamiento",
                quiz_multi: "TODO: Cuestionario multirespuesta",
                text_auto: "TODO: Respuesta autocorregida",
                type_challenge: "TODO: Tipo de prueba",
                placeholder_type: "TODO: Selecciona un tipo",
                alert_type: "TODO: Selecciona un tipo de prueba",
                id_name: "TODO: Nombre Identificativo",
                placeholder_name_id: "TODO: Nombre identificador de la prueba...",
                alert_name_id: "TODO: Pon un nombre identificativo a la prueba.",
                placeholder_name: "TODO: Nombre de la prueba...",
                description: "TODO: Descripción",
                placeholder_description: "TODO: Descripción de la prueba...",
                image_quiz: "TODO: Imagen cuestionario",
                max_money: "TODO: Dinero maximo",
                need_material: "TODO: Necesita Material",
                material: "TODO: Material",
                placeholder_material: "TODO: Material necesario...",
                expected_answer: "TODO: Respuesta Esperada",
                placeholder_expected_answer: "TODO: Respuesta esperada",
                file_1: "TODO: File 1",
                file_2: "TODO: File 2",
                action_challenge_quiz: "TODO: Action Challenge Quiz",
                photo: "TODO: Foto",
                video: "TODO: Video",
                team_photo: "TODO: Foto equipo",
                template: "TODO: Plantilla",
                max_points: "TODO: Puntos maximos",
                cost: "TODO: Coste",
                video_duration: "TODO: Video duration",
                short_description: "TODO: Descripción corta",
                placeholder_short_description: "TODO: Descripción corta...",
                help_gm: "TODO: Ayuda para puntuar",
                help_file: "TODO: Imagen Solución",
                internal_description: "TODO: Descripción interna",
                placeholder_internal_description: "TODO: Descripción de la prueba interna..."
            }
        },
        settings: {
            settings: "TODO: Configuraciones",
            settings_list: "TODO: Lista de configuraciones",
            ipad: {
                name: "TODO: Nombre",
                id_name: "TODO: Nombre Identificativo",
                placeholder_name_id: "TODO: Nombre identificador de la prueba...",
                alert_name_id: "TODO: Pon un nombre identificativo a la prueba.",
                main_color: "TODO: Color principal",
                secondary_color: "TODO: Color secundario",
                stopwatch_color: "TODO: Color cronometro",
                background_image: "TODO: Imagen de fondo",
                points_icon: "TODO: Icono de puntos",
                challenges: "TODO: Retos",
                challenge: "TODO: Reto"
            },
            tapas: {
                name: "TODO: Nombre",
                id_name: "TODO: Nombre Identificativo",
                placeholder_name_id: "TODO: Nombre identificador de la prueba...",
                alert_name_id: "TODO: Pon un nombre identificativo a la prueba.",
                secondary: "TODO: Secundarios",
                decoration: "TODO: Decoración",
                processing_time: "TODO: Tiempo de elaboración",
                ingredients: "TODO: Ingredientes",
                item_shop: "TODO: Tienda de utensilios",
                setting: "TODO: Configuración"
            },
            action: {
                name: "TODO: Nombre",
                id_name: "TODO: Nombre Identificativo",
                placeholder_name_id: "TODO: Nombre identificador de la prueba...",
                alert_name_id: "TODO: Pon un nombre identificativo a la prueba.",
                challenges: "TODO: Retos",
                categories: "TODO: Categorias",
                challenge: "TODO: Reto",
                game_mode: "TODO: Modo de juego"
            }
        },
        erp_settings: {
            organization: "TODO: Organización",
            name: "TODO: Nombre",
            placeholder_name: "TODO: Nombre",
            alert_name: "TODO: Debe tener un nombre",
            logo: "TODO: Logo",
            billing: "TODO: Facturación",
            placeholder_billing: "TODO: Datos de facturación...",
            list_activities: "TODO: Lista de Actividades",
            new_activity: "TODO: Nueva Actividad",
            search: "TODO: Buscar",
            placeholder_search: "TODO: Buscar por nombre",
            in_person: "TODO: presencial",
            online: "TODO: Online",
            modality: "TODO: Modalidad",
            alert_modality: "TODO: Selecciona una modalidad",
            general_media: "TODO: Archivo General",
            pricing_list: "TODO: Lista de precios",
            activity_name: "TODO: Nombre",
            placeholder_activity_name: "TODO: Nombre de la actividad (Cliente)",
            duration: "TODO: Duración",
            placeholder_duration: "TODO: Duracion de la actividad",
            description: "TODO: Descripción",
            placeholder_description: "TODO: Descipcion de la actividad",
            requirements: "TODO: Requerimientos",
            placeholder_requirements: "TODO: Requirimentos de la actividad",
            includes: "TODO: Incluye",
            placeholder_includes: "TODO: Que incluye la actividad",
            dynamic: "TODO: Dinámica",
            placeholder_dynamic: "TODO: Dinámica de la actividad",
            budget_description: "TODO: Descripción del presupuesto",
            placeholder_budget_description: "TODO: Descripción del presupuesto",
            media: "TODO: Archivo solo de este idioma",
            extras: "TODO: Extras",
            price: "TODO: Precio",
            activity: "TODO: Actividad",
            type_price: "TODO: Tipo de precio",
            alert_type_price: "TODO: Seleciona un tipo de precio",
            new_extra: "TODO: Nuevo Extra",
            preview_pdf: "TODO: Previsualizar PDF",
            extra_deleted: "TODO: Se ha eliminado el extra",
            extra_not_deleted: "TODO: No se ha eliminado el extra",
            placeholder_name_extra: "TODO: Nombre del extra",
            placeholder_description_extra: "TODO: Descripción del extra",
            extra: "TODO: Extra",
            total: "TODO: Total",
            by_person: "TODO: Por Persona",
            final_page_budget: "TODO: Pagina final presupuesto",
            new_final_page_budget: "TODO: Nueva pagina final presupuesto",
            final_page_budget_deleted: "TODO: Se ha eliminado la pagina final presupuesto",
            final_page_budget_not_deleted: "TODO: No se ha podido eliminar la pagina final presupuesto",
            placeholder_description_activity: "TODO: Descripcion de la actividad",
            question: "TODO: Pregunta",
            placeholder_question: "TODO: Pregunta",
            all_activities: "TODO: Todas las actividades",
            no_activity: "TODO: Sin actividad",
            placeholder_activity: "TODO: Filtrar por actividad",
            questions_activities: "TODO: Preguntas sobre actividades"
        },
        previews: {
            menu: "TODO: Menu"
        },
        login: {
            description: "TODO: Aplicación de Teaming Labs para la realización de eventos de Team Building",
            email: "TODO: Email",
            placeholder_email: "TODO: Example@email.com",
            password: "TODO: Contraseña",
            placeholder_password: "TODO: At least 8 characters",
            sign_in: "TODO: Iniciar Sesión",
            forgor_password: "TODO: Contraseña olvidada"
        },
        formGenerator: {
            pricing: {
                new_price: "TODO: + Nueva linea de precio",
                min_persons: "TODO: Personas minimo",
                max_persons: "TODO: Personas maximo",
                total_price: "TODO: Precio total",
                person_price: "TODO: Precio persona",
                price: "TODO: Precio "
            }
        },
        dialogs: {
            sure: "TODO: Estas seguro?",
            erase: "TODO: Borrar",
            erase_record: "TODO: Borrar registro",
            delete_team: "TODO: Eliminar equipo",
            delete: "TODO: Eliminar",
            delete_colon: "TODO: Eliminar: ",
            cancel: "TODO: Cancelar",
            name: "TODO: Nombre",
            add: "TODO: Añadir",
            placeholder_team: "TODO: Equipo..",
            edit_team: "TODO: Editar equipo",
            save: "TODO: Guardar",
            demo_limit: "TODO: Las actividades de demo solo permiten 2 equipos",
            link_staff: "TODO: Enlace Staff",
            link_admin: "TODO: Enlace Administrador",
            link_results: "TODO: Enlace Resultados",
            go_results: "TODO: Ir a resultados",
            add_team: "TODO: Añadir equipo",
            material_recalculate: "TODO: Al crear el equipo se recalculara el material",
            warning: "TODO: Aviso",
            add_team_money: "TODO: Añadir dinero equipo",
            link_teams: "TODO: Enlace Equipos",
            challenge_delete: "TODO: Se elminiara la prueba: ",
            create: "TODO: Crear",
            challenge: "TODO: Reto",
            placeholder_challenge: "TODO: Selecciona un reto",
            warning_change_template: "TODO: Al cambiar la plantilla se borraran todas las pruebas",
            team: "TODO: Equipo",
            points_negative: "TODO: Puntos (pueden ser negativos)",
            money_negative: "TODO: Dinero (puede ser negativo)",
            minutes: "TODO: Minutos",
            seconds: "TODO: Segundos",
            substract: "TODO: Restar",
            expected_answer: "TODO: Respuesta esperada: ",
            challenge_score: "TODO: Puntuar reto",
            activate: "TODO: Activar",
            material_recalculate_delete: "TODO: Al eliminar el equipo se recalculara el material",
            add_route: "TODO: Añadir ruta",
            confirm: "TODO: Confirmar",
            accept: "TODO: Aceptar",
            activity: "TODO: Actividad",
            language: "TODO: Idioma",
            placeholder_language: "TODO: Selecciona un idioma",
            logo: "TODO: Logo",
            sure_delete_acitivty: "TODO: Estas seguro que queires borrar al actividad?"
        },
        gm: {
            modals: {
                add_monitor: "TODO: Añadir monitor",
                save: "TODO: Guardar",
                name: "TODO: Nombre",
                placeholder_name: "TODO: Nombre Monitor",
                saved_monitor: "TODO: Monitor guardado",
                continue: "TODO: Continuar",
                change: "TODO: Cambiar",
                exit: "TODO: Salir",
                cancel: "TODO: Cancelar",
                exit_event: "TODO: Salir del evento",
                password: "TODO: Contraseña"
            },
            dashboards: {
                configuration: "TODO: Configuración",
                link_ranking: "TODO: Enlace Ranking",
                link_results: "TODO: Enlace Resultados",
                link_monitors: "TODO: Enlace Monitores",
                link_admin: "TODO: Enlace Admin",
                link_presentation: "TODO: Enlace Presentación",
                link_teams: "TODO: Enlace Equipos",
                list_ingredients: "TODO: Lista de ingredientes",
                link_favorite_images: "TODO: Enlace Imagenes favoritas",
                total: "TODO: Total",
                remaining: "TODO: Restante",
                round_ends: "TODO: Ronda finaliza",
                round: "TODO: Ronda",
                play: "TODO: Jugar",
                resume: "TODO: Continuar",
                pause: "TODO: Pausar",
                pass_round: "TODO: Saltar Ronda",
                time_ranking: "TODO: Ver tiempo en ranking",
                results: "TODO: Resultados",
                add_team: "TODO: Añadir Equipo",
                add_teams: "TODO: Añadir Equipos",
                order_points: "TODO: Ordenar por puntos",
                add_challenges_first: "TODO: Añade pruebas primero",
                general: "TODO: General",
                material: "TODO: Material",
                event_configuration: "TODO: Configura el evento primero",
                back: "TODO: Atras",
                save: "TODO: Guardar",
                challenges: "TODO: Retos",
                score_categories: "TODO: Categorias de puntuación",
                defense: "TODO: DEFENSA",
                presentation: "TODO: PRESENTACIÓN",
                flavor: "TODO: SABOR",
                money: "TODO: DINERO",
                round_time: "TODO: Tiempo por ronda (segundos)",
                tapas_time: "TODO: Tiempo tapas (segundos)",
                type: "TODO: Tipo",
                placeholder_type: "TODO: Selecciona un tipo",
                ingredient: "TODO: Ingredientes",
                shop: "TODO: Tienda",
                select_challenge: "TODO: Selecciona algun reto",
                select_activity: "TODO: Selecciona una actividad",
                select_time_round: "TODO: Pon un tiempo por ronda",
                select_time_tapas: "TODO: Pon un tiempo para realizar las tapas",
                select_categories: "TODO: Porfavor selecciona las categorias",
                team_turn: "TODO: Turno del equipo",
                type_election: "TODO: Tipo de elección",
                round_election: "TODO: Ronda de elección",
                round_total: "TODO: Ronda total",
                time_elaboration: "TODO: Tiempo de elaboración",
                name_team: "TODO: Ponle un nombre al equipo",
                number_teams: "TODO: Numero de equipos",
                total_ingredients: "TODO: Total de ingredientes",
                extra_ingredients: "TODO: Ingredientes extras",
                total_decoration: "TODO: Total decoración",
                extra_decoration: "TODO: Decoración extra",
                missing_ingredients: "TODO: Faltan ingredientes",
                list_download: "TODO: Descargar Lista",
                ingredients: "TODO: Ingredientes",
                saved: "TODO: Guardado",
                start_game: "TODO: Comenzar Juego",
                gm: "TODO: Game Master",
                readed: "TODO: Leido",
                sended: "TODO: Enviado",
                message_team: "TODO: Escribe un mensaje al equipo ",
                message_all_teams: "TODO: Los mensajes que escribas se enviaran a todos los equipos",
                your_message: "TODO: Tu mensaje",
                upload_image: "TODO: Subir imagen",
                add_emoji: "TODO: Añadir emoticono",
                send_message: "TODO: Enviar mensaje",
                all: "TODO: Todos",
                challenge: "TODO: Reto",
                id_name: "TODO: Nombre identificativo",
                latitude: "TODO: Latitud",
                longitude: "TODO: Longitud",
                activations_radius: "TODO: Radio de activación",
                categories_points: "TODO: Categorias de puntuación",
                must_upper: "TODO: OBLIGATORIA",
                first_upper: "TODO: PRIMER",
                type_activation: "TODO: Tipo de activación",
                qr_upper: "TODO: QR",
                location_upper: "TODO: UBICACIÓN",
                first: "TODO: Primera",
                must: "TODO: Obligatoria",
                add_challenges: "TODO: Añade pruebas",
                select_latitude: "TODO: Selecciona una latitud",
                select_longitude: "TODO: Selecciona una longitud",
                placeholder_id_name: "TODO: Pon un nombre identificativo",
                placeholder_template: "TODO: Selecciona una plantilla",
                put_duration: "TODO: Pon una duración",
                initial_position: "TODO: Posición inicial",
                not_template: "TODO: Sin plantilla",
                template: "TODO: Plantilla",
                placeholder_search_address: "TODO: Busca una dirección...",
                duration_seconds: "TODO: Duración (segundos)",
                duration_hours: "TODO: Duración Horas",
                duration_minutes: "TODO: Duración Minutos",
                categories: "TODO: Categorias",
                time_action: "TODO: Pon un tiempo para realizar los retos",
                routes: "TODO: Rutas",
                team_no_route: "TODO: Hay equipos sin ruta",
                changes_not_saved: "TODO: Cambios no guardados",
                route: "TODO: Ruta",
                order: "TODO: Orden: ",
                name_route: "TODO: Nombre",
                placeholder_name_route: "TODO: Nombre de la ruta",
                event: "TODO: Evento",
                select_setting: "TODO: Selecciona una configuración",
                initial_challenges: "TODO: Retos Iniciales"
            },
            utq: {
                change_monitor: "TODO: Cambiar Monitor",
                round: "TODO: Ronda",
                ends: "TODO: Finaliza",
                start: "TODO: Empezar",
                team_upper: "TODO: EQUIPO",
                position_upper: "TODO: POSICIÓN",
                points_upper: "TODO: PUNTOS",
                team: "TODO: Equipo",
                placeholder_team: "TODO: Selecciona un equipo",
                score: "TODO: Puntuación",
                rate: "TODO: Puntuar",
                instructions_material: "TODO: Instrucciones y material",
                name_event: "TODO: Pon un nombre al evento",
                select_team: "TODO: Selecciona un equipo",
                operation: "TODO: Funcionamiento",
                material: "TODO: Material"
            },
            tapas: {
                round_ends: "TODO: Ronda finaliza",
                score_missing: "TODO: Quedan pruebas sin puntuar a los equipos",
                start_challenges: "TODO: Empezar Retos",
                start_selection: "TODO: Empezar Selección Ingredientes",
                start_elaboration: "TODO: Empezar Tiempo de Elaboración",
                pass_round: "TODO: Saltar Ronda",
                selecting_ingredients: "TODO: Seleccionando ingredientes",
                pass_selection: "TODO: Saltar selección",
                team_turn: "TODO: Turno del equipo",
                type_selection: "TODO: Tipo de selección",
                round_selection: "TODO: Ronda de selección",
                round_total: "TODO: Ronda total",
                votes: "TODO: Votaciones",
                type_choice: "TODO: Tipo de elección",
                top_1: "TODO: Top 1",
                top_2: "TODO: Top 2",
                top_3: "TODO: Top 3",
                round: "TODO: Ronda",
                time_eleboration: "TODO: Tiempo de elaboración",
                start: "TODO: Empezar",
                challenges: "TODO: Retos",
                placeholder_challenge: "TODO: Selecciona un reto y pulsa empezar",
                orders: "TODO: Pedidos",
                change_money: "TODO: Modificar dinero",
                shop: "TODO: Tienda",
                challenges_results: "TODO: Resultados retos",
                ingredient_selection: "TODO: Selección de ingredientes",
                winners: "TODO: Ganadores",
                placeholder_select_team: "TODO: Selecciona un equipo",
                category: "TODO: Categoria",
                rate: "TODO: Puntuar",
                defense: "TODO: Defensa",
                show: "TODO: Presentación",
                flavour: "TODO: Sabor"
            },
            ipad: {
                time_game: "TODO: Tiempo de juego",
                start_game: "TODO: Comenzar Juego",
                add_delete_time: "TODO: Añadir/Quitar Tiempo",
                hide_ranking: "TODO: Esconder Ranking",
                show_ranking: "TODO: Enseñar Ranking",
                lock_challenges: "TODO: Bloquear Pruebas",
                unlock_challenges: "TODO: Desbloquear Pruebas",
                challenges: "TODO: Pruebas",
                clean_filters: "TODO: Limpiar filtros",
                add_delete_points: "TODO: Añadir/Quitar Puntos",
                activate_challenge: "TODO: Activar reto",
                no_route: "TODO: Sin Ruta",
                instructor: "TODO: Monitor",
                player: "TODO: Jugador",
                team: "TODO: Equipo",
                points: "TODO: Puntos",
                challenges_done: "TODO: Retos Realizados",
                last_challenge: "TODO: Ultimo Reto",
                hour: "TODO: Hora",
                must: "TODO: Obligatorios",
                optionals: "TODO: Opcionales"
            },
            action: {
                start: "TODO: Empezar",
                next: "TODO: Siguiente",
                time_game: "TODO: Tiempo de juego",
                start_game: "TODO: Comenzar Juego",
                add_delete_time: "TODO: Añadir/Quitar Tiempo",
                ranking: "TODO: Ranking",
                finish_game: "TODO: Finalizar Juego",
                reactivate_game: "TODO: Reactivar Juego",
                challenges: "TODO: Retos",
                clean_filters: "TODO: Limpiar filtros",
                team_filter: "TODO: Filtro Equipo",
                challenge_filter: "TODO: Filtro Reto",
                add_delete_points: "TODO: Añadir/Quitar Puntos",
                add_delete_money: "TODO: Añadir/Quitar Dinero",
                activate_quiz: "TODO: Activar Cuestionario",
                ranking_normal: "TODO: Normal",
                ranking_coop: "TODO: Cooperativo",
                ranking_both: "TODO: Ambos",
                ranking_hidden: "TODO: Escondido",
                activate_challenge: "TODO: Activar reto",
                add_favorite: "TODO: Añadir a favoritos",
                delete_favorite: "TODO: Quitar de favoritos",
                quizzes: "TODO: Cuestionarios",
                challenge_not_start: "TODO: Todavia no ha comenzado ningun reto",
                challenges_pick: "TODO: Retos a recoger",
                challenges_return: "TODO: Retos a devolver",
                challenges_material: "TODO: Material retos",
                update_stock: "TODO: Modificar Stock",
                stock_upper: "TODO: STOCK: ",
                stock: "TODO: Stock",
                team_upper: "TODO: EQUIPO",
                challenge_upper: "TODO: PRUEBA",
                returned_upper: "TODO: DEVUELTO",
                delivered_upper: "TODO: ENTREGADO",
                team: "TODO: Equipo",
                points: "TODO: Puntos",
                money: "TODO: Dinero",
                quizzes_done: "TODO: Cuestionarios Realizados",
                challenges_done: "TODO: Retos Realizados",
                last_challenge: "TODO: Ultimo Reto",
                material: "TODO: Material",
                save: "TODO: Guardar"
            }
        },
        messages: {
            round_pass: "TODO: Ronda saltada",
            canceled: "TODO: Cancelado",
            deleted: "TODO: eliminado",
            updated: "TODO: actualizado",
            added: "TODO: añadido",
            score_ok: "TODO: Puntuación enviada correctamente",
            score_not_ok: "TODO: No se pudo enviar la puntuación",
            score_add: "TODO: Pon una puntuación",
            select_team: "TODO: Selecciona un equipo",
            team_error: "TODO: No se ha podido añadir el equipo",
            team_correctly: "TODO: Equipo {msg} correctamente",
            incorrect_fields: "TODO: Hay campos incorrectos en el formulario",
            tapas_succesfull_updated: "TODO: Actividad Guerra de Tapas actualizada correctamente",
            tapas_not_succesfull_updated: "TODO: No se ha podido actualizar la actividad Guerra de Tapas",
            tapas_succesfull_added: "TODO: Actividad Guerra de Tapas añadida correctamente",
            tapas_not_succesfull_added: "TODO: No se ha podido añadir la actividad Guerra de Tapas",
            select_passed: "TODO: Selección saltada",
            link_copied: "TODO: Enlace copiado",
            votes_deleted: "TODO: Votos eliminados correctamente",
            votes_not_deleted: "TODO: No se han podido eliminar los votos",
            money_updated: "TODO: Dinero actualizado",
            teams_added: "TODO: Equipos añadidos correctamente",
            game_started: "TODO: Juego Empezado",
            deleted_ok: "TODO: Eliminado correctamente",
            deleted_not_ok: "TODO: No se ha podido elminiar",
            updated_not_ok: "TODO: No se ha podido actualizar",
            select_challenge: "TODO: Selecciona un reto",
            challenges: "TODO: Retos",
            template: "TODO: Plantilla",
            warning_template: "TODO: Si no eliges una plantilla tendras que crear todos los retos manualmente.",
            ipad_hunt_not_succesfull_added: "TODO: No se ha podido añadir la actividad Ipad Hunt",
            challenge_activated: "TODO: Reto Activado",
            points_updated: "TODO: Puntos Actualizados",
            time_updated: "TODO: Tiempo Actualizado",
            correctly_scored: "TODO: Puntuado correctamente",
            incorrectly_scored: "TODO: No se ha podido puntuar correctamente",
            team: "TODO: Equipo",
            action_updated_ok: "TODO: Actividad Action Challenge actualizada correctamente",
            action_updated_not_ok: "TODO: No se ha podido actualizar la actividad Action Challenge",
            action_added_ok: "TODO: Actividad Action Challenge añadida correctamente",
            action_added_not_ok: "TODO: No se ha podido añadir la actividad Action Challenge",
            route_created: "TODO: Ruta Creada",
            route_deleted: "TODO: Ruta Borrada",
            route_saved: "TODO: Ruta Guardada",
            saved: "TODO: Guardado",
            select_category: "TODO: Escoger una categoria",
            must_select: "TODO: Debes selecionar top1 y top 2",
            vote_sent: "TODO: Votación enviada",
            vote_error: "TODO: Error al votar",
            correct_score: "TODO: Puntuado Correctamente",
            incorrect_score: "TODO: No se ha podido puntuar correctamente",
            game_reactivated: "TODO: Juego Reactivado",
            game_finished: "TODO: Juego Finalizado",
            write_quesiton: "TODO: Escribe una pregunta",
            event_deleted: "TODO: Evento borrado",
            select_activity: "TODO: Debes selecionar la actividad",
            event_created: "TODO: Evento creado",
            event_updated: "TODO: Evento editado",
            event_not_created: "TODO: No se ha podido crear el evento"
        }
    }