import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { defineStore, acceptHMRUpdate } from "pinia"
import {type IpadHuntData} from "@/stores/apps/interfaces/ipad_hunt_interfaces"
import { useContentStore } from "./content";
import { useEventsStore } from "./events";
import { getActiveChallenge, checkContentDownloaded, getContentDataBase, updateData, sendFile, getChallengeDone } from "@/views/ipad_hunt/ipadHuntUtils"
import dayjs from "@/utils/dayjs";
import _ from "lodash";
import { sound_alert } from  "@/utils/notifications";
export const useIpadHuntStore = defineStore("ipad_hunt", {
	state: () => ({ipadData: {} as IpadHuntData, 
		challenges: [], 
		routes:[],
		ws : null, 
		dataDownloaded: false, 
		dataDownloadedModal: true, 
		errorDownloaded: false,
		counterContentDownload:0,
		maxContentDownload:0,
		player_challanges: [],
		player_done_challanges:[],
		player_route:[],
		showAnswer: false,
		answersShuffled: [],
		userAnswer: null,
		currentChallenge: null as null|Number,
		player_positions:[],
		challengeToCheck:[],
		challengeToStart:[],
		challengeToStartDb:[],

		// colors
		primaryColor:"",
		secondaryColor:"",
		timerColor:"",
		backgroundUrl:"",
	}),
	actions: {
		async updateStoreChallengeToStartDb ()
		{
			const data = await getActiveChallenge();
			this.challengeToStartDb = data;
		},
		async getIpadHuntData (api_key:string)
		{
			await simpleAxios("get", "events/event_full/" + api_key, {})
			.then((response: AxiosResponse) => {
                console.log(response.data);
                
                this.ipadData = response.data
				this.primaryColor = response.data.ipad_settings.primary_color;
				this.secondaryColor = response.data.ipad_settings.secondary_color;
				this.timerColor = response.data.ipad_settings.timer_color;
				this.backgroundUrl = response.data.ipad_settings.background;

			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async getRoutes (api_key:string)
		{
			await simpleAxios("get", "IpadHunt/route/" + api_key, {})
			.then((response: AxiosResponse) => {
                this.routes = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async setIpadData (data:IpadHuntData)
		{
            this.ipadData = data
		},
		async getChallenges (api_key:string)
		{
			await axiosToken("get", "IpadHunt/challenge/" + api_key, {})
			.then((response: AxiosResponse) => {
                this.challenges = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async updatePlayerPositions (data:any)
		{
            // find if data is the array if not update
			const postionDataIndex =  this.player_positions.findIndex(e=> e.team_id == data.team_id)
			const new_data = {
				...data,
				lastUpdate: new Date()
			}
			if( postionDataIndex >= 0)
			{
				console.log("udpate pos");
				this.player_positions[postionDataIndex] = new_data;
			}
			else
			{
				this.player_positions.push(new_data);
			}
		},
		updateChallengeToCheck(data:any)
		{
			
			this.challengeToCheck = data;
		},
		async addNewChallengeToStart(data:any)
		{
			const eventStore = useEventsStore()
			if( Object.keys(eventStore.current_ipad_team).length === 0)
			{
				return;
			}
			const challenge_doneIndex =  eventStore.current_ipad_team.challenges.findIndex(e=> e.challenge == data.id);
			if(challenge_doneIndex >= 0 )
			{
				if( eventStore.current_ipad_team.challenges[challenge_doneIndex].done == true)
				{
					return;
				}
			}
			if(!this.challengeToStart.find( e=> e.challenge == data.id) )
			{
				sound_alert("challenge");
				const challenge_id = this.getPlayerChallenge(data.id)
				const dataContent = await getContentDataBase(challenge_id.challenge)
				dataContent.challenge = data.id;
				dataContent.button_id = data.id;
				dataContent.loading_btn = false;
				dataContent.challengeData = challenge_id;
				this.challengeToStart.push(dataContent)

			}
		},
		setColorStyle(settings:any)
		{
			console.log(settings);
			
			this.primaryColor = settings.primary_color;
			this.secondaryColor = settings.secondary_color;
			this.timerColor = settings.timer_color;
			this.backgroundUrl = settings.background;
		},

		// actions
		setShowRanking(event_api_key:string, status:boolean)
		{
			axiosToken("put", "events/event_full/" + event_api_key, {
				show_ranking: status,
			})
		},
		setShowChallenge(event_api_key:string,status:boolean)
		{
			axiosToken("put", "events/event_full/" + event_api_key, {
				show_challenge: status,
			})
		}
		
	},
	persist: {
		// paths: ["events"]
	},
	getters:{
		getActiveDoneChallenge:state=>{
			const eventStore = useEventsStore()
			if( !( 'challenges' in eventStore.current_ipad_team))
			{
				return null
			}
			const data = eventStore.current_ipad_team.challenges;
			const challengeToStart = state.challengeToStartDb;
			const combinedArray = [...challengeToStart, ...data];
			const uniqueChallengesMap = new Map();
			combinedArray.forEach(item => {
				if (!uniqueChallengesMap.has(item.challenge)) {
					uniqueChallengesMap.set(item.challenge, item);
				}
			});

			return Array.from(uniqueChallengesMap.values());
			// merge both 


		},
		getAllRoutes: state=>{
			return state.routes.slice();
		},
		getRoute: state => (id:number): any | undefined  =>{
			return state.routes.find(e => e.id == id)
		},
		getTeamRoute: state=>{
			const eventStore = useEventsStore()
			if(_.isEmpty(eventStore.current_team) )
			{
				return null
			}
			const id = eventStore.current_team.id;
			let team_route = null
			state.routes.forEach(r => {
				console.log(r);
				
				const found = r.teams.find(t => t == id)
				console.log(found);
				
				if(found != undefined)
				{
					team_route = r
				}
			})
			return team_route
		},
		getChallenge: state => (id:number): any | undefined  =>{
			return state.challenges.find(e => e.id == id)
		},
		getPlayerChallenge: state => (id:number): any | undefined  =>{
			return state.player_challanges.find(e => e.id == id)
		},
		getSecondsTime: (state) =>{
			if(state.ipadData.timer_finish == undefined)
			{
				return;
			}
			const now:Date = new Date();			
			return new Date(state.ipadData.timer_finish);
		},
		getSecondsTimeChallenge: state => (id:number): any | undefined  =>{
			const eventStore = useEventsStore()
			
			const challenge_done =  state.challengeToStartDb.find(e=> e.challenge == id);
			
			if(challenge_done != undefined)
			{
				const now:Date = new Date();			
				return new Date(challenge_done.start_time);
			}	
			return 0
		},
		getChallengeToCheck: (state) =>{
			let challengeToCheck =[]
			const eventStore = useEventsStore()
			eventStore.ipad_team.forEach(e =>{
				e.challenges.forEach(async(ch) =>{
					if( ch.done == true && ch.game_master_check == false)
					{						
						challengeToCheck.push(
							{
								challenge:ch,
								team_name:e.team.name_id + " - " + e.team.name,
								team_id: e.team.id
								
							}
						);
					}
				})
			})
			return challengeToCheck;
		},
		getDataGameMasterRanking: state => (): any | undefined => {
			const eventStore = useEventsStore()
			const dataTeam = JSON.parse(JSON.stringify(eventStore.teams));
			const ranking = dataTeam.sort((a, b) => b.points - a.points)
			console.log(ranking);
			
			ranking.forEach(r => {
				let challenges_done = 0
				let last_challenge = ""
				let last_challenge_start = null
				let last_challenge_finish = null;
				const challenges_status = []
				
				state.player_challanges.sort((a, b) => a.id - b.id).forEach(pc => {
					
					let challenges = undefined
					let challenge = undefined
					if(eventStore.ipad_team.find(it => it.team.id == r.id) != undefined)
					{
						challenges = eventStore.ipad_team.find(it => it.team.id == r.id).challenges
						challenge = challenges.find(c => c.challenge == pc.id)
					}
					
					if(challenge == undefined)
					{
						challenge = {name: pc.name, done: false, must: pc.must}
					}
					else
					{
						challenge.name = pc.name
						challenge.must = pc.must
					}
					
					if(challenge.done)
					{
						challenges_done++
						// last_challenge = challenge.name
						if( last_challenge_finish )
						{
							console.log(challenge.timer_finish,last_challenge_finish );
							
							if(challenge.timer_finish > last_challenge_finish  )
							{
								last_challenge = challenge.name
								last_challenge_start = dayjs(challenge.timer_finish).format("HH:mm")
								last_challenge_finish = challenge.timer_finish
							}
						}
						else
						{
							last_challenge_finish = challenge.timer_finish
							last_challenge = challenge.name
							last_challenge_start = dayjs(challenge.timer_finish).format("HH:mm")
						}
					}
					challenges_status.push(challenge)
				})
				r.challenges_status = challenges_status
				r.full_name = r.name_id + ". " + r.name
				r.challenges_done = challenges_done
				r.last_challenge = last_challenge
				r.last_challenge_start = last_challenge_start
				r.key = r.id
			});
			return ranking
		},
		getColumnsGameMasterRanking:state=>{
			const columns =[]
			columns.push({
				title: "Nombre",
				key: "name"
			})
			columns.push({
				title: "Puntos",
				key: "points"
			}),
			columns.push({
				title: "Pruebas Realizadas",
				key: "challenges_done"
			}),
			columns.push({
				title: "Ultima prueba",
				key: "last_challenge"
			})

			return columns
		},
		getChallengesNSelect: state =>{
			let options = []
			
			state.player_challanges.forEach((e)=>{
				options.push({
					label:e.name ,
					value:e.id,
				}
				)
			})
			return options
		},
		getSecondsRemaining: state => {
			if(state.ipadData.timer_finish == undefined)
			{
				return;
			}
			return new Date(state.ipadData.timer_finish);
		},
		getChallengeTeamDone: state => {
			const eventStore = useEventsStore()
			if(!('challenges' in eventStore.current_ipad_team))
			{
				return null
			}
			return eventStore.current_ipad_team.challenges
			
		},
		getChallengeMapActive(): Array<any> {
			const challengeTeamDone = this.getActiveDoneChallenge;
			const teamRoute = this.getTeamRoute;
			if (this.player_challanges.length == 0 || teamRoute == null || challengeTeamDone== null )
			{
				return []
			}
			let indexRoute = 0;
			
			for (let index = 0; index < teamRoute.order.length; index++) {
				const indexChallenge = teamRoute.order[index];
				let indexFound = false;
				for (let index = 0; index < challengeTeamDone.length; index++) {
					const element = challengeTeamDone[index];
					if( element.challenge == indexChallenge)
					{
						indexRoute ++;
						indexFound = true;
						break;
					}
				}
				if( !indexFound)
				{
					break;
				}
			}
			
			let challengeMap =[]
			this.player_challanges.forEach(challenge=>{
				console.log(challenge.must == true &&  !teamRoute.order.includes(challenge.id) && teamRoute.init_order.includes(challenge.id));
				
				if(challenge.first == true || challenge.active_localation == false)
				{

				}
				else if ( challenge.must == true &&  !teamRoute.order.includes(challenge.id) && teamRoute.init_order.includes(challenge.id))
				{
					challenge.must = false;
					challengeMap.push(challenge)
					
				}
				else if(challenge.must == true && indexRoute <=  teamRoute.order.length )
				{
					
					if(challenge.id == teamRoute.order[indexRoute] )
					{
						challenge.last = false;
						if(teamRoute.order.length ==  indexRoute + 1)
						{
							challenge.last = true;
						}
						challengeMap.push(challenge)
					}
				}
				
				else
				{
					let find = false;
					for (let index = 0; index < challengeTeamDone.length; index++) {
						const element = challengeTeamDone[index];
						if(element.challenge == challenge.id )
						{
							find = true;
							
						}
					}
					if( !find )
					{
						challengeMap.push(challenge)
					}
				}
			})
			return challengeMap
		}
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useIpadHuntStore, import.meta.hot))
}
